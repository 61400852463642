<template>
  <section>
    <b-row class="mb-1 match-height">

      <b-col
        cols="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="6"
            >
              <h4>Pacote atual</h4>
            </b-col>
            <b-col cols="6">
              <h4><strong>Indicações</strong></h4>
            </b-col>
            <b-col cols="6">
              <b-badge variant="light-success">
                Ativado
              </b-badge>
            </b-col>
            <b-col cols="6">
              <h5><strong>10</strong></h5>
            </b-col>
            <b-col
              cols="6"
              class="mt-2"
            >
              <p>Campanhas</p>
            </b-col>
            <b-col
              cols="6"
              class="mt-2"
            >
              <p><strong>Ilimitadas</strong></p>
            </b-col>
            <b-col cols="6">
              <p>Renovação</p>
            </b-col>
            <b-col cols="6">
              <p><strong>Manual</strong></p>
            </b-col>
            <b-col cols="6">
              <p>Vencimento</p>
            </b-col>
            <b-col cols="6">
              <p><strong>12/05/2022</strong></p>
            </b-col>
          </b-row>
        </b-card>
        <!-- card -->
        <b-card
          bg-variant="primary"
          text-variant="white"
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center flex-column"
            >
              <b-card-title class="text-white">
                Preço por lead*
              </b-card-title>
            </b-col>
          </b-row>

          <template v-for="range in ranges">
            <b-row
              :key="range.title"
              class="mt-1"
            >
              <b-col cols="6">
                <b-card-text class="d-flex align-items-center flex-column">
                  {{ range.title }}
                </b-card-text>
              </b-col>
              <b-col cols="6">
                <b-card-text class="d-flex align-items-center flex-column">
                  <template v-if="range.price">
                    {{ new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(range.price) }}
                  </template>

                  <b-button
                    v-else
                    size="sm"
                    variant="success"
                    class="d-flex justify-content-center"
                    :disabled="$can('edit', 'settingFinancial') === false"
                    @click="alertRequireMoreIndications()"
                  >
                    Solicitar
                  </b-button>
                </b-card-text>
              </b-col>
            </b-row>
          </template>

          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center flex-column mt-2"
            >
              <b-card-text>
                *Válido por 6 meses
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>

        <span style="text-align: center">
          <strong> <feather-icon
            icon="GiftIcon"
            size="15"
          /> 10 leads de presente para você começar</strong>
        </span>

      </b-col>

      <!--  -->

      <b-col
        cols="8"
        class="px-1"
      >
        <b-card>
          <b-row no-gutters>
            <b-col
              cols="12"
            >
              <h3>Pacote</h3>
              <p>Você pode contratar a quantidade de indicações que quiser.</p>
            </b-col>
          </b-row>
          <!--  -->
          <b-row
            class="py-2"
          >
            <b-col
              cols="4"
              class="d-flex align-items-center flex-column px-3"
            >
              <h5>Quantidade de indicações</h5>
              <b-form-spinbutton
                v-model="indications"
                variant="primary"
                class="mt-2 spinbutton-primary"
                min="1"
                max="999"
              />
            </b-col>
            <b-col
              cols="4"
              class="d-flex align-items-center flex-column"
            >
              <h5>Valor por indicação</h5>
              <h4
                class="mt-2"
              >{{ new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(getActualPricePerIndication.price) }}</h4>
            </b-col>
            <b-col cols="4">
              <b-card
                class="box-total"
                bg-variant="primary"
                text-variant="white"
              >
                <h5>Total da compra</h5>
                <template v-if="getActualPricePerIndication.actualRange > 0">
                  <small>de <strike class="px-1">
                    {{ new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(getTotalPrice.oldPrice) }}
                  </strike> por</small>
                  <br>
                </template>

                <strong>
                  {{ new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(getTotalPrice.newPrice) }}
                </strong>
              </b-card>
            </b-col>
          </b-row>
          <!--  -->
          <b-row class="py-2">
            <b-col cols="12">
              <vue-slider
                v-model="indications"
                :min="1"
                :max="999"
              />
            </b-col>
          </b-row>
          <!--  -->

          <b-row
            v-if="$can('edit', 'settingFinancial')"
            class="py-2 mt-2"
          >
            <b-col
              cols="8"
              class="d-flex px-2"
            >
              <b-form-checkbox
                v-model="renew"
                checked="true"
                class="custom-control-success"
                name="check-renew"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>

              <div class="px-2">
                <h4>Renovação automática</h4>
                <p>Assim você não se preocupa em lembrar de renovar, renovaremos quando o vencimento ou creditos chegarem ao fim!</p>
              </div>
            </b-col>
            <b-col cols="4">
              <b-button
                size="lg"
                variant="success"
                class="d-flex justify-content-center"
                @click="goToCheckout"
              >
                Contratar pacote
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

  </section>

</template>

<script>

// import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import VueSlider from 'vue-slider-component'

import {
  BRow, BCol, BCard, BButton, BBadge, BCardTitle, BCardText, BFormSpinbutton, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'HirePackage',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardTitle,
    BCardText,
    VueSlider,
    BFormSpinbutton,
    BFormCheckbox,
  },
  data() {
    return {
      indications: 1,
      renovation: false,
      ranges: null,
      activePlan: null,
      renew: true,
    }
  },
  computed: {
    getActualPricePerIndication() {
      let actualRange = 0
      let price = 0

      if (this.ranges) {
        if (this.indications <= this.ranges[0].toEnd[1]) actualRange = 0
        if (this.indications >= this.ranges[1].toEnd[0] && this.indications <= this.ranges[1].toEnd[1]) actualRange = 1
        if (this.indications >= this.ranges[2].toEnd[0] && this.indications <= this.ranges[2].toEnd[1]) actualRange = 2
        if (this.indications >= this.ranges[3].toEnd[0] && this.indications <= this.ranges[3].toEnd[1]) actualRange = 3
        if (this.indications >= this.ranges[4].toEnd[0] && this.indications <= this.ranges[4].toEnd[1]) actualRange = 4

        price = this.ranges[actualRange].price
      }

      return { actualRange, price }
    },
    getTotalPrice() {
      const oldPrice = this.ranges ? this.indications * this.ranges[0].price : 0
      const newPrice = this.indications * this.getActualPricePerIndication.price

      return { oldPrice, newPrice }
    },
    getIdSelectPlan() {
      return this.ranges.filter(plan => plan.price === this.getActualPricePerIndication.price)[0].id
    },
  },
  mounted() {
    this.getRangesIndications()
  },
  methods: {
    alertRequireMoreIndications() {
      this.$swal({
        title: '<span class="mt-4 font-weight-bolder">Recebemos sua intenção de mudança de plano :)</span>',
        // icon: 'info',
        html:
          '<p class="mt-2">Em breve você vai receber um contato da nossa equipe, fique atento!</p>',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Ok!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    getRangesIndications() {
      useJwt.getAllRangesIndications()
        .then(response => {
          const rangesByApi = response.data.result.filter(range => range.isActive === true)
          this.ranges = rangesByApi.sort((a, b) => ((a.price < b.price) ? 1 : -1))
        })
        .catch(error => error)
    },
    goToCheckout() {
      const infoToCheckout = {
        planId: this.getIdSelectPlan,
        indications: this.indications,
        totalPrice: this.getTotalPrice.newPrice,
        unitPrice: this.getActualPricePerIndication.price,
        renew: this.renew,
      }

      const queryParam = btoa(JSON.stringify(infoToCheckout))
      this.$router.push({
        name: 'checkout-credit-card',
        query: { infoVoucher: queryParam },
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-slider.scss';

    .spinbutton-primary {
        button {
            background-color: #6270ff !important; //#7367f0
            color: #fff;
        }
    }

    .box-total {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h5 {
            color: #FFF;
            margin: 0.2rem 0
        }

        strong {
            font-size: 1.5rem;
        }
    }

    .swal2-popup{
        background-image: url('/images/alert-bg-financial.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
</style>
